<template>
  <div class="custom-button" @click="el => !disabled && $emit('click', el)">
    <slot></slot>
    <div v-if="disabled" class="disabled"/>
  </div>
</template>
<script>
export default {
  props: {
    disabled: Boolean,
  }

}
</script>
<style lang="scss" scoped>
  .custom-button {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    color: var(--blue-500);
    font-weight: 700;
    font-size: 0.9rem;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: var(--blue-100);
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
    }

    .disabled {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 8px;
      background-color: var(--blue-100);
      opacity: 0.5;
      cursor: no-drop;
    }
  }
</style>
